import React from 'react'
import './App.css'
import Header from './Header';
import CompanyList  from './CompanyList';
// import Pfooter from './Pfooter';
import CustomFooter from './snippets/Customer/customFooter';


function AllCompanies() {
  return (
    <div>
        <Header />
        <CompanyList />
        <CustomFooter />
    </div>
  )
}

export default AllCompanies