import React from 'react'
import { Link } from "react-router-dom";
// import img2 from "../Images/logo.png";
import R_Logo from "../Images/reliablesoftwarelogo.png";
import '../Styles/Dashboard/DashboardHeader.scss'
import { Company } from "../assets/Data";
import HeaderImage from '../Images/HeaderImage.js';

function TopHeader() {
  return (
    <div className="top-header row w-100">
      <div className="col-md-2 col-sm-12 logo-container">
        <Link to="/">
          <HeaderImage />
        </Link>
      </div>

      <div className="col-md-7 col-sm-12">
        <h1 className="admin-headerH1 text-center">
          <Link to="/">{Company[0].fullName}</Link>
        </h1>
      </div>

      <div className="col-md-3 col-sm-12 logo-container">
        <img
          src={R_Logo}
          height={100}
          width={100}
          className="R"
          alt="Reliable software logo"
        />
      </div>
    </div>
  );
}

export default TopHeader