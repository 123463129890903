import React from "react";
import Slider from "./Slider";
// import MarqueeLComList from "./MarqueeLComList";
// import { BackTop } from "antd";

const HomeComponent = () => {
  return (
    <div className="home-component w-100">
      <Slider />
    </div>
  );
};

export default HomeComponent;
