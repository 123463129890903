import "./App.css";
function Pfooter() {
  return (
    <>
      <div className="P_footer">
        <p>
          By Reliable Software, Goa.{" "}
          <span className="white">Ph: 7969668000</span>{" "}
        </p>
      </div>
    </>
  );
}

export default Pfooter;
