import axios from "axios";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ApiHandler from "../../ApiHandler";
import img2 from "../../Images/logo.png";
import "./Company.css";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import GlobalVariable from "../../GlobalVariable";
import TransferData from "../../TransferData";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import { Triangle } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import LoaderTri from "../Loader/LoaderTri";
import HeaderImage from "../../Images/HeaderImage";
import { userActions } from "../../store/user-slice";
import { useDispatch } from "react-redux";
import CustomFooter from '../../snippets/Customer/customFooter'

import DashboardHeader from "../../snippets/Dashboard/DashboardHeader";

const initialValues = {
  from: dayjs(),
  to: dayjs(),
};
export default function CompanyDashboard(e) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showList, setShowList] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [selectedOrderData, setSelectedOrderData] = useState([]);
  const [selectedCompPenClaim, setSelectedCompPenClaim] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [range, setRange] = useState([
    {
      startdate: new Date(),
      endDate: addDays(new Date(), ""),
      key: "selection",
    },
  ]);
  const [companyCode, setCompanyCode] = useState(null);
  const [dates, setDates] = useState(initialValues);

  const [alldatesData, setAlldatesData] = useState([]);
  const [selectedStocksandSales, setSelectedStocksandSales] = useState([]);
  const [selectedStocksandSalesFOOTER, setSelectedStocksandSalesFOOTER] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const refOne = useRef(null);
  const customerName = JSON.parse(localStorage.getItem("user-info"));

  // after click, it will be false
  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);

  const hideOnEscape = (e) => {
    console.log(e.key);
    if (e.key === "Escape") {
      setOpenDate(false);
    }
  };
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpenDate(false);
    }
  };

  // company names - api
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user-info"));

    if (user) {
      ApiHandler.post({
        // to keep global ---> 'https://popsv3.relysoft.in'
        url: "/api/OwnAppConfigs/CompanyLoginConfig/",

        data: {
          wClientid: GlobalVariable.wcClient.toString(),
          repCode: user.code,
        },
      })
        .then((result) => {
          console.log(result);
          console.log(result.data.companies);
          console.log(result.data.reports);
          setReportsData(result.data.reports);
          setShowList(result.data.companies);
        })
        .catch((err) => {
          console.error(err);
          if (
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            console.log(err.response);
            //   RefreshTokenHandler();
            //  ToastError("Token has expired Please refresh");
          }
        });
    }
  }, []);

  // to get data after selection category

  const getData = useCallback(
    (elm, date) => {
      setIsLoading(true);

      const user = JSON.parse(localStorage.getItem("user-info"));
      const localUrl = JSON.parse(localStorage.getItem("LocalURL"));
      if (!companyCode) {
        setIsLoading(false);
        toast.error("Company Code not Found");
        return;
      }

      ApiHandler.post({
        url: localUrl + elm + "/",
        data: {
          startdate: dayjs(dates.from).format("YYYYMMDD"),
          enddate: dayjs(dates.to).format("YYYYMMDD"),
          code: companyCode,
          // startdate: "20210101",
          // enddate: "20230502",
          // code: user.code,
        },
        useUserUrl: true,
      })
        .then((result) => {
          console.log(result);
          console.log(result.data);
          setAlldatesData(result.data);
          setSelectedOrderData(result.data);
          setSelectedStocksandSales(result.data.stockAndSaleDetails);
          setSelectedStocksandSalesFOOTER(result.data.footer);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          if (
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            console.log(err.response);
            //   RefreshTokenHandler();
            //  ToastError("Token has expired Please refresh");
          }
        });
    },
    [companyCode, dates]
  );

  // log out function

  function logout() {
    window.localStorage.removeItem("user-info");
    window.localStorage.removeItem("app-type");
    dispatch(userActions.setLogin(false));

    history.push("/");
  }

  // download files on excel

  function handleExportDataExcel() {
    console.log(selectedOrderData);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(selectedOrderData);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "Reports.xlsx");
  }

  function handleExportDataExcelStockandSales() {
    console.log(selectedStocksandSales);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(selectedStocksandSales);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "Reports.xlsx");
  }

  // Date selection

  const handleSelects = (date) => {
    let filtered = alldatesData.filter((product) => {
      let productDate = new Date(product["orderDate"]);
      return (
        productDate >= date.selection.startDate &&
        productDate <= date.selection.endDate
      );
    });
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    // setSelectedOrderData(filtered);
    setSelectedStocksandSales(filtered);
    console.log(date.selection.startDate);
    console.log(date.selection.endDate);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  return (
    <>
      <div className="product_header">
        <DashboardHeader />
      </div>

      <br />
      <br />
      <div className="row mx-0">
        <div className="col-lg-6 col-8 text-center">
          <h5 className="customH5">Hello! {customerName?.name}</h5>
        </div>
        <div className="col-lg-6 col-4 mb-2 text-center">
          <button className="customButton" onClick={logout}>
            log out
          </button>
        </div>
      </div>

      <div className="row mx-0">
        <div className="col-lg-6 offset-lg-1 mb-2">
          <select
            className="form-control "
            value={companyCode ? companyCode : ""}
            onChange={(e) => {
              setCompanyCode(e.target.value);
              setDisabled(false);
              setShowResults(e.target.value === "" ? false : true);
            }}
          >
            <option value={""}>Select Company Name</option>
            {showList.map((P, i) => {
              return (
                <option value={P.comCode} key={i}>
                  {P.companyName}
                </option>
              );
            })}
          </select>
        </div>
        {showResults && (
          <>
            <div className="offset-lg-6"></div>
            <div className="col-lg-3 offset-lg-1 mb-2">
              <select
                value={selectedData}
                className="form-control"
                onChange={(e) => {
                  setSelectedData(e.target.value);
                }}
              >
                <option value={""}>Select Report</option>
                {reportsData.map((elm, i) => {
                  return (
                    <option key={i} value={elm.endPoint}>
                      {elm.reportName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-lg-3 mb-2">
              {
                <DatePicker.RangePicker
                  size="large"
                  value={[dates.from, dates.to]}
                  format="DD-MM-YYYY"
                  onChange={(e) => {
                    console.log(e);

                    if (e) {
                      setDates({
                        from: e[0],
                        to: e[1],
                      });
                    } else {
                      setDates({ from: null, to: null });
                    }
                  }}
                />
              }
            </div>
            <div className="col-lg-2 text-center">
              <button
                type="button"
                className="submit_selectedData mb-2"
                onClick={() => {
                  setSelectedOrderData([]);
                  setSelectedCompPenClaim([]);
                  setSelectedStocksandSales([]);
                  setDisabled(true);
                  getData(selectedData);
                }}
              >
                Submit
              </button>
            </div>
            <div className="col-lg-2 text-center">
              {selectedOrderData?.length > 0 && (
                <button
                  className="downLoad_selectedData mb-2"
                  onClick={handleExportDataExcel}
                >
                  Download
                </button>
              )}
              {selectedStocksandSales?.length > 0 && (
                <button
                  className="downLoad_selectedData_StockandSales mb-2"
                  onClick={handleExportDataExcelStockandSales}
                >
                  Download
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <div className="comp_data">
        <div
          style={{
            width: "100%",
            // overflow: "hidden"
            overflowX: "scroll",
          }}
        >
          {Array.isArray(selectedOrderData) && selectedOrderData.length > 0 && (
            <div className="p-2">
              <Table striped>
                <thead className="threAd">
                  <tr className="threAd_tr">
                    {Object.keys(selectedOrderData[0]).map((data, ii) => {
                      return (
                        <th style={{ border: "1px solid black" }} key={ii}>
                          {data}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                {selectedOrderData.map((data, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        {Object.keys(selectedOrderData[0]).map((el, ii) => {
                          return (
                            <td style={{ border: "1px solid black" }} key={ii}>
                              {data[el]}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            </div>
          )}

          {selectedData === "api/ownapp/GetStockandsale" && (
            <div className="Data p-2">
              <Table striped>
                <thead className="threAd">
                  <tr className="threAd_tr">
                    <th>{TransferData.flag}</th>
                    <th>{TransferData.name}</th>
                    <th style={{ textAlign: "right" }}>{TransferData.pack}</th>
                    <th style={{ textAlign: "right" }}>{TransferData.rate}</th>
                    <th>{TransferData.prevLastSale}</th>
                    <th>{TransferData.prevSale}</th>
                    <th>{TransferData.opening}</th>
                    <th>{TransferData.receipt}</th>
                    <th>{TransferData.sales}</th>
                    <th>{TransferData.salesRtn}</th>
                    <th>{TransferData.saleValue}</th>
                    <th>{TransferData.purRtn}</th>
                    <th>{TransferData.others}</th>
                    <th>{TransferData.closing}</th>
                    <th>{TransferData.cl_Value}</th>
                  </tr>
                </thead>

                <tbody className="tboDY">
                  {Array.isArray(selectedStocksandSales) &&
                    selectedStocksandSales.map((items, i) => {
                      return (
                        <tr key={i}>
                          <td>{items.flag}</td>
                          <td style={{ color: "black" }}>
                            {items.product_Name}
                          </td>
                          <td>{items.pack}</td>
                          <td>{items.rate}</td>
                          <td>{items.prevLastSale}</td>
                          <td>{items.prevSale}</td>
                          <td>{items.opening}</td>
                          <td>{items.receipt}</td>
                          <td>{items.sales}</td>
                          <td>{items.salesRtn}</td>
                          <td>{items.saleValue}</td>
                          <td>{items.purRtn}</td>
                          <td>{items.others}</td>
                          <td>{items.closing}</td>
                          <td>{items.cl_Value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                  <LoaderTri />
                </div>
              )}
              <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                {" "}
                NOTES
              </h5>
            </div>
          )}
        </div>
        <div className="p-2">
          {Array.isArray(selectedStocksandSalesFOOTER) &&
            selectedStocksandSalesFOOTER.map((aa, i) => {
              return (
                <li key={i} className="Stock_Footer_Notes">
                  {aa.notes}
                </li>
              );
            })}
        </div>
      </div>
      
      <CustomFooter />

    </>
  );
}
