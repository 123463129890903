import { useState } from "react";
import img2 from "../../../Images/slider/2.jpg";
import img3 from "../../../Images/slider/3.jpg";

// import { Carousel, Image } from 'react-bootstrap';
import { Carousel, Image } from "antd";

function Slider() {
  const [selectedImage, setSelectedImage] = useState(0);
  const [allImages, setAllImages] = useState([img2, img3]);

  // useEffect(() => {
  //     setInterval(() => {
  //         setSelectedImage(selectedImage => selectedImage > 1 ? 0 : selectedImage + 1)
  //     }, 2500);
  // }, [])

  return (
    <div className="carousel-section">
      <Carousel autoplay autoplaySpeed={2500}>
        {allImages.map((item, index) => (
          <Image
            src={item}
            key={index}
            width={"100%"}
            height="500px"
            alt={"ImagePlaceholder" + index}
          />
        ))}
        {/* </Carousel.Item> */}
      </Carousel>
      
    </div>
  );
}
export default Slider;
