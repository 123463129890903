import Header from "./Header";
import Pfooter from "./Pfooter";

export default function All_P() {
  return (
    <>
      <Header />
      <h1 style={{ textAlign: "center" }}>This Is All Product Page</h1>
      <Pfooter />
    </>
  );
}
