const Company = [
  {
    name: "Solapur Pharma Hub LLP",
    fullName: "Solapur Pharma Hub LLP",
    phone: {
      primary: [
        {
          label: "primary",
          number: "+91-8888698855",
        },
      ],
      alternate: [],
    },
    email: "solapurpharmahubllp@gmail.com",
    address: [
      "Solapur Pharma Hub LLP, South Kasba, Budhavar Peth, Solapur, Maharashtra 413001",
      "South Kasba, Budhavar Peth, Solapur, Maharashtra 413001",
    ],
  },
];

const BankDetails = [
  {
    label: "Bank Details:",
    value: "Solapur Janta Sahakari Bank Ltd",
  },
  {
    label: "A/C. No.:",
    value: "5870011000002821",
  },
  {
    label: "IFSC Code:",
    value: "SJSB0000099",
  },
];

export { Company, BankDetails };